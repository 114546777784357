const zh_CN = {
  form: {
    c: {
      selectStartTime: '请选择起始时间',
      selectEndTime: '请选择结束时间',
      confirm: '确定',
    },
    resumeBasicInfo: {
      inputName: '请填写姓名',
      inputEmail: '请填写邮箱',
      inputMobile: '请填写手机号',
      inputQQ: '请填写QQ',
      inputWechat: '请填写微信号',
      selectGender: '请选择性别',
      selectBirthday: '请选择出生年月',
      selectBase: '请选择居住城市',
      selectExp: '请选择工作年限',
      selectEdu: '请选择最高学历',
      inputGraduatedForm: '请填写毕业院校',
    },
    resumeEdu: {
      inputSchool: '请输入院校名称',
      inputMajor: '请输入专业名称',
    },
  },
  c: {
    privacyPolicy: "隐私政策",
    termsOfService: "服务条款",
    readAndAgree: "我已阅读并同意",
    and: '和',
    age: '岁',
    signOut: '退出登录',
    signIn: '登录',
    signUp: '注册',
    getCaptcha: '获取验证码',
    regetCaptcha: '`${countDown.value}秒后重新获取`',
    inputEmail: '请输入邮箱',
    emailCheck: '请检查邮箱格式是否正确',
    inputCaptcha: '邮箱验证码',
    inputPassword: '请输入至少8位的复杂密码',
    checkPassword: '请确认密码',
    captchaLengthCheck: '请检查验证码',
    sureSignUp: '确定',
    openEmail: '打开邮箱',
    copyRight: '版权所有 © 2022-， 笃笃科技 闽ICP备2022010380号-1',

    selectTag: '请选择标签',
    save: '保存',
    edit: '编辑',
    add: '添加',

    userCenter: '个人中心',
    myResume: '我的简历',
    settings: '设置',

    noLimit: '不限',
    all: '全部',

    download: '下载',
    features: '功能丨特点',
    supportAndPlan: '支持丨计划',
    bugAndRequest: '问题丨需求',
    createIssue: '反馈Bug',
    createDiscussion: '贡献想法、需求',
    cooperation: '合作',
    contact: '联系我们',

    comingSoon: '敬请期待...',
    businessCooperation: '商务合作',
    email: '邮箱',
    contacts: '联系人',

    downloadRequire: '请先登录，以便您及时获得beta对应版本的正式版授权',
    checkPasswordNotNull: '确认下密码哈~',
    checkPasswordNotSame: '世界上没有两片相同的树叶，注册可以有两个相同的密码哟~',
  },

  d: {
    corvusYe: '大叶',
  },

  about: {
    'us': '关于我们',
  },

  m: {
    home: '首页',
    plaza: '组件零售',
    products: '产品',
    job: '工作机会',
    openSource: '开源项目',
    ngbatisDocs: 'ngbatis 开发文档',
    ngbatis: 'ngbatis (已捐赠)',
    waoUi: 'wao_ui',
    flutterComponents: 'flutter_components',
    flutterGraphView: 'flutter_graph_view',
    pubFlutterGraphView: 'https://pub.flutter-io.cn/packages/flutter_graph_view',
    documents: '文档',
  },
  job: {
    publish: '发布岗位',
    companyOtherPosition: '其他在招岗位',
    delivery: '投递',
    pickResume: '选择简历',
    addCompany: '添加企业',
    enterCompanyName: '请填上企业名称 ~',
    enterCompanyEmail: '简历投递邮箱地址：hrexample.com',
    enterCompanyDescr: '企业简介写这里喔 ~',
    industry: '所属行业',
    companyDescr: '企业简介',
    companyIndex: '企业首页',
    email: '简历投递邮箱地址',

    positionList: '岗位列表',
    positionDetail: '岗位详情',

    enterPositionName: '请输入岗位名称',
    enterPositionNo: '请输入岗位内部编码',
    enterMinSalary: '最低薪资（月）',
    enterMaxSalary: '最高薪资（月）',

    selectBase: '请选择所在地区',
    selectExp: '请选择经验要求',
    selectEdu: '请选择最低学历',
    selectNature: '请选择岗位性质',
    qBase: '所在地区',
    qExp: '经验要求',
    qEdu: '学历要求',
    qNature: '岗位性质',

    resume: {
      add: '添加简历',
      title: '我的简历',
      basicInfo: '基本信息',
      edu: '教育/培训经历',
      exp: '工作经验',
      project: '项目经验',
      evaluation: '自我评价',
    }
  },
  products: {
    graphDesk: {
      title: 'GraphDesk',
      slogan: '为图数据库而生',
      desc: '一款为图数据库量身定做的数据库工具，有志于兼容多种数据源，助力开发者完成向图数据库的过渡。',
      version: 'community-beta（环境要求 java 1.8+，64位）',
      features: {
        f1: '多数据源本地统一管理，一触即达',
        f2: '存储常用GQL，把大脑空间留给生活',
        f3: '传承表格思维，让使用习惯与图共生',
        f4: '打破传统工具单行表头限制，呈现复杂数据结构',
        s1: '数据表格',
        s2: '图谱可视化',
      },
      qqGroupDownload: '加群下载',
      qqGroupFeedback: '加群反馈',
    },
    starShadow: {
      title: '星影',
      slogan: '图数据库客户端',
      desc: '一款为图数据库量身定做的数据库工具，有志于兼容多种数据源，努力成为开发者图探索路上重要的伙伴。',
      version: '1.0.0-beta',
      features: {
        f1: '多数据源本地统一管理，一触即达',
        f2: '存储常用GQL，把大脑空间留给生活',
        f3: '传承表格思维，让使用习惯与图共生',
        f4: '打破传统工具单行表头限制，呈现复杂数据结构',
        s1: '数据表格',
        s2: '图谱可视化',
      },
      qqGroupDownload: '加群下载',
      qqGroupFeedback: '加群反馈',
    },
  },
  h5: {
    wechatLogin: '使用微信授权登录',
    loginSuccess: '登录成功',
    loginFailed: '登录失败',
    welcomeFollow: '欢迎关注我们的公众号<br>以便在碰到问题时及时地联系我们',
    failedRescan: '二维码已过期，请重新扫码',
    rescan: '重新扫码',
    closePage: '关闭页面',
  },
}

export default zh_CN